/* CSS for WELLE */

body {
    /* background: black; */
    font-family: "Roboto", sans-serif !important;
    /* color: white; */
}
.mainBody {
    margin: auto;
    max-width: 1100px;
    /* border: 3px solid green; */
    padding-left: 0.6em !important;
    padding-right: 0.6em !important;
    padding-bottom: 200px !important;
}
h1 {
    font-size: 45px !important;
    margin: -4px 0 !important;
    letter-spacing: 0.1em;
}
h2 {
    font-size: 18px !important;
    /* font-weight: bold !important; */
    letter-spacing: 0.2em;
    /* text-transform: uppercase; */
}
h3 {
    font-family: "Roboto", sans-serif !important;
    font-size: 15px !important;
    /* font-weight: bold !important; */
    letter-spacing: 0.2em;
    padding-top: 3.5em;
    padding-bottom: 2em;
}
/* w3 css button overwrite */
.buttonSmall {
    padding: 0px 4px !important;
}
.selector {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
}
.flexDiv {
    display: flex;
    justify-content: left;
}
.flexDivInst {
    display: flex;
    justify-content: left;
    max-width: 20em;
    padding-right: 3em;
}
.flexDivMenu {
    display: flex;
    justify-content: left;
}
.titleDiv {
    /* padding-bottom: 2em; */
}
.menuTitle {
    margin-top: 0.5em;
    padding-right: 2em;
    padding-bottom: 2em;
}
.menuTitle a {
    text-decoration: none;
}
.fileManagement {
}
.fileManagement p {
    padding-left: 0em !important;
    padding-bottom: 0em !important;
    margin-right: 2.7em !important;
    margin-top: 0.1em !important;
    margin-bottom: 0.1em !important;
}

.menu {
    padding-bottom: 3em;
}
.menuSettings {
    /* margin-top: 0.1em; */
    min-width: 14em;
}
.menuSettings p {
    padding-left: 0em !important;
    padding-bottom: 0em !important;
    margin-right: 2.7em !important;
    margin-top: 0.1em !important;
    margin-bottom: 0.1em !important;
}
.recFile {
    margin-left: 0.3em;
}
.menuSettings select {
    width: 100% !important;
}
.menuTransport {
    padding-bottom: 0em !important;
    min-width: 11em;
}
.menuTransport p {
    padding-left: 0em !important;
    padding-bottom: 0em !important;
    margin-right: 1.7em !important;
    margin-top: 0.3em !important;
    margin-bottom: 0.3em !important;
}
.sounds {
    padding-left: 0em;
    padding-right: 0em;
    margin-top: -0.5em !important;
    margin-left: 0em;
    margin-right: 0em;
}
.sounds a {
    padding-right: 0.5em;
}
.mainConsole {
    padding-left: 0em;
    padding-right: 0em;
    margin-left: 0em;
    margin-right: 0em;
}
.instLine {
    font-weight: bold !important;
    padding-top: 0em;
    padding-left: 0.1em;
    display: flex;
    justify-content: left;
}
.instHeadLine {
    /* font-weight: bold !important; */
    padding-top: 0em;
    padding-bottom: 0.2em;
    padding-left: 0.1em;
    display: flex;
    justify-content: left;
}
.instName {
    min-width: 7em;
    max-width: 7em;
    padding-left: 0.1em;
    margin-right: 0.1em;
}
.instVol {
    min-width: 6em;
    max-width: 6em;
    margin-right: 0.1em;
}
.instRand {
    min-width: 5.3em;
    max-width: 5.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    /* margin-right: 1.4em; */
}
.instPattern {
    word-wrap: break-word;
    min-width: 21.4em;
    max-width: 21.4em;
}
.instPatternPart {
    word-wrap: break-word;
    min-width: 2.5em !important;
    max-width: 2.5em !important;
    padding-left: 0.2em;
}
.instEnvelope {
    word-wrap: break-word;
    min-width: 12em;
    max-width: 12em;
}
.beatDark {
    background-color: #e4e4e4 !important;
    /* color: #f3f3f3; */
}

#mainInput:focus,
#input:focus {
    background-color: #ffffff;
    border: 2px solid black;
    font-weight: bold;
}
#mainInput {
    /* background: black; */
    /* color: white; */
    outline: none;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 1px;
    border-color: #0e0e0e;
    padding: 5px 2px;
    /* font-size: 0.8em; */
    width: 100%;
    word-wrap: break-word;
}

#console {
    /* font-size: 0.9em; */
    word-wrap: break-word;
    padding-left: 0.2em;
    margin-top: 1.4em;
    margin-bottom: 0.3em;
}
.mainConsole {
    /* margin-top: 15px; */
    /* border: 1px solid rgb(0, 0, 0); */
    /* background-color: rgb(189, 189, 189); */
    margin-bottom: 30em !important;
}
.mainConsole h3 {
    margin: 0.5em 0em !important;
    padding-top: 0em !important;
    padding-bottom: 0em !important;
    font-weight: normal !important;
}

#consoleLine {
    /* color: white; */
    font-size: 0.9em;
    margin-top: 0.02em;
    margin-bottom: 0.02em;
    width: 97%;
    text-indent: -0.8em;
    margin-left: 0.8em;
}

.selectedInst {
    margin-left: -2px;
    border-left: 2px solid rgb(60, 179, 56);
}
input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 0.1rem;
}
input[type="checkbox"]:checked {
    filter: invert(1) hue-rotate(210deg) brightness(2.7);
    /*  */
}
label {
    padding-left: 0.4rem;
    padding-right: 1rem;
}
.stateHtml {
    min-width: 0.6em;
    margin-right: 0.1em;
    margin-left: 0.3em;
}
.uploadStatus {
    font-size: 10px;
    padding-left: 0.5em;
}
/* loader */
#loaderDiv {
    padding-top: 0.6em;
    /* padding-right: 3em !important; */
}
.loader {
    /* 
    padding-bottom: 14em; */
    margin-top: 0.6em;
    margin-left: 1em;
    border: 0.3em solid #f3f3f3;
    border-radius: 50%;
    border-top: 0.3em solid #000000;
    width: 2em;
    height: 2em;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* tutorial styling */
.commands {
    min-width: 6em;
    display: inline-block;
    padding-right: 1em;
    font-weight: bold;
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .titleDiv {
        padding-bottom: 0em;
        display: block;
    }
    .flexDivMenu {
        display: block;
    }
    .menuTitle {
        margin-top: 0.5em;
        padding-right: 1.5em;
    }
    .instName {
        min-width: 5.5em;
        max-width: 5.5em;
        padding-left: 0.1em;
        margin-right: 0.1em;
    }
    .commands {
        min-width: 2em;
        display: inline-block;
        padding-right: 1em;
        font-weight: bold;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
}
